  import React, { useLayoutEffect, useState } from 'react'
  import { SafeAreaView, StatusBar, StyleSheet, Text, View, KeyboardAvoidingView, Platform, ScrollView, TextInput, Keyboard, TouchableWithoutFeedback } from 'react-native'
  import { Avatar } from 'react-native-elements'
  import { TouchableOpacity } from 'react-native'
  import { AntDesign, FontAwesome, Ionicons } from '@expo/vector-icons'
  import * as firebase from 'firebase'
  import { db, auth } from '../firebase'

  const ChatScreen = ({ navigation, route }) => {

    const [input, setInput] = useState('')
    const [messages, setMessages] = useState([])

    useLayoutEffect(() => {
      navigation.setOptions({
        title: 'Chat',
        headerBackTitleVisible: false,
        headerTitleAlign: 'left',
        headerTitle: () => (
          <View style={{
            flexDirection: 'row',
            alignItems: 'center'
          }}>
            <Avatar rounded
              source={{ uri:  messages[0]?.data.photoURL
                // || 'https://kooledge.com/assets/default_medium_avatar-57d58da4fc778fbd688dcbc4cbc47e14ac79839a9801187e42a796cbd6569847.png'
              }}
            />
            <Text
              style={{
                color: '#fff',
                marginLeft: 10,
                fontWeight: 700
              }}
            >{ route.params.chatName }</Text>
          </View>
        ),
        headerLeft: () => (
          <TouchableOpacity style={{ marginLeft: 10 }}
            onPress={navigation.goBack}
          >
            <AntDesign name='arrowleft' size={24} color='#fff' />
          </TouchableOpacity>

        ),
        headerRight: () => (
          <View style={{
            flexDirection: 'row',
            justifyContent: 'space-between',
            width: 80,
            marginRight: 20
          }}>
            <TouchableOpacity>
              <FontAwesome name='video-camera' size={24} color='#fff' />
            </TouchableOpacity>
            <TouchableOpacity>
              <Ionicons name='call' size={24} color='#fff' />
            </TouchableOpacity>
          </View>
        )
      })
    }, [navigation, messages])

    const sendMessage = () => {
      Keyboard.dismiss()

      db.collection('chats').doc(route.params.id).collection('messages').add({
        timestamp: firebase.firestore.FieldValue.serverTimestamp(),
        message: input,
        displayName: auth.currentUser.displayName,
        email: auth.currentUser.email,
        photoURL: auth.currentUser.photoURL
      })

      setInput('')
    }

    useLayoutEffect(() => {
      const unsuscribe = db
        .collection('chats')
        .doc(route.params.id)
        .collection('messages')
        .orderBy('timestamp', 'desc')
        .onSnapshot(snapshot => setMessages(
          snapshot.docs.map(doc => ({
            id: doc.id,
            data: doc.data()
          }))
        ))

      return unsuscribe
    }, [route])

    return (
      <SafeAreaView style={{
        flex: 1,
        backgroundColor: '#white'
      }}>
        <StatusBar style='light' />
        <KeyboardAvoidingView
          behavior={Platform.OS === 'ios' ? 'padding' : 'height'}
          style={styles.container}
          keyboarVerticalOffset={90}
        >
          <TouchableWithoutFeedback onPress={Keyboard.dismiss}>
            <>
              <ScrollView contentContainerStyle={{
                paddingTop: 15
                }} >
              {messages.map(({ id, data }) => (
                data.email === auth.currentUser.email
                  ? (
                    <View key={id} style={styles.receiver}>
                      <Avatar
                        position='absolute'
                        rounded size={30}
                        // for the web
                        containerStyle={{
                          position: 'absolute',
                          bottom: -15,
                          right: -5
                        }}
                        bottom={-15}
                        right={-5}
                        source={{ uri: data.photoURL }}
                      />
                      <Text style={styles.receiverText}>{data.message}</Text>
                    </View>
                  ) : (
                    <View key={id} style={styles.sender}>
                      <Avatar
                        position='absolute'
                        rounded size={30}
                        // for the web
                        containerStyle={{
                          position: 'absolute',
                          bottom: -15,
                          right: -5
                        }}
                        bottom={-15}
                        right={-5}
                        source={{ uri: data.photoURL }}
                      />
                      <Text style={styles.senderText}>{data.message}</Text>
                      <Text style={styles.senderName}>{data.displayName}</Text>
                    </View>
                  )
              ))}
            </ScrollView>
              <View style={styles.footer}>
                <TextInput
                  placeholder='Signal message'
                  style={styles.textInput}
                  value={input}
                  onChangeText={text => setInput(text)}
                  onSubmitEditing={sendMessage}
                />
            <TouchableOpacity onPress={sendMessage} activeOpacity={0.5}>
              <Ionicons name='send' sieze={24} color='#2b68e6' />
            </TouchableOpacity>
          </View>
          </>
          </TouchableWithoutFeedback>
      </KeyboardAvoidingView>
    </SafeAreaView>
  )
}

export default ChatScreen

const styles = StyleSheet.create({
  container: {
    flex: 1
  },
  footer: {
    flexDirection: 'row',
    alignItems: 'center',
    width: '100%',
    padding: 15
  },
  textInput: {
    bottom: 0,
    height: 40,
    flex: 1,
    marginRight: 15,
    backgroundColor: '#ececec',
    padding: 10,
    color: 'grey',
    borderRadius: 30
  },
  receiver: {
    padding: 15,
    backgroundColor: '#ececec',
    alignSelf: 'flex-end',
    borderRadius: 20,
    marginRight: 15,
    marginBottom: 20,
    maxWidth: '80%',
    position: 'relative'
  },
  sender: {
    padding: 15,
    backgroundColor: '#2b68e6',
    alignSelf: 'flex-start',
    borderRadius: 20,
    margin: 15,
    maxWidth: '80%',
    position: 'relative'
  },
  senderName: {
    left: 10,
    paddingRight: 10,
    fontSize: 10,
    color: '#fff'
  }
})
