import * as firebase from 'firebase'
import 'firebase/firestore'
import 'firebase/auth'

const firebaseConfig = {
  apiKey: "AIzaSyD4LaYjAHYJknePg-F6HZt215lTDCXqyvI",
  authDomain: "signal-clone-1ee59.firebaseapp.com",
  projectId: "signal-clone-1ee59",
  storageBucket: "signal-clone-1ee59.appspot.com",
  messagingSenderId: "57162267400",
  appId: "1:57162267400:web:56286056a739b214f60abd"
};

let app

if (firebase.apps.length === 0) {
  app = firebase.initializeApp(firebaseConfig)
} else {
  app = firebase.app()
}

const db = app.firestore()
const auth = firebase.auth()

export { db, auth }
