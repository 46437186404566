import React, { useEffect, useState } from 'react'
import { StyleSheet } from 'react-native'
import { ListItem, Avatar } from 'react-native-elements'
import { db } from '../firebase'
import chatImage from '../assets/chat.png'

const CustomListItem = ({ id, chatName, enterChat }) => {

  const [chatMessages, setChatMessages] = useState([])

  useEffect(() => {
    const unsuscribe = db
      .collection('chats')
      .doc(id)
      .collection('messages')
      .orderBy('timestamp', 'desc')
      .onSnapshot(snapshot => {
        setChatMessages(snapshot.docs.map(doc => doc.data()))
      })

    return unsuscribe
  })

  const returnLogo = () => {
    if (chatMessages?.[0]?.photoURL) {
      return ({ uri : chatMessages?.[0]?.photoURL })
    } else {
      return chatImage
    }
  }

  return (
    <ListItem
      key={id}
      bottomDivider
      onPress={() => enterChat(id, chatName)}
    >
          <Avatar
            rounded
            source={returnLogo()}
          />
        <ListItem.Content>
          <ListItem.Title style={{ fontWeight: '800' }}>
            {chatName}
          </ListItem.Title>
          <ListItem.Subtitle numberOfLines={1} ellipsizeMode='tail'>
            { chatMessages?.[0]?.displayName } : { chatMessages?.[0]?.message }
          </ListItem.Subtitle>
        </ListItem.Content>
    </ListItem>
  )
}

export default CustomListItem

const styles = StyleSheet.create({})
