import React, { useLayoutEffect, useState } from 'react'
import { StyleSheet, View, KeyboardAvoidingView } from 'react-native'
import { Button, Input, Text } from 'react-native-elements'
import { StatusBar } from 'expo-status-bar'
import { auth } from '../firebase'

const RegisterScreen = ({ navigation }) => {
  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [imageUrl, setImageUrl] = useState('')

  useLayoutEffect(() => {
    navigation.setOptions({
      headerBackTitle: 'Login'
    })
  }, [navigation])

  const register = () => {
    auth.createUserWithEmailAndPassword(email, password)
      .then(authUser => {
        authUser.user.updateProfile({
          displayName: name,
          photoURL: imageUrl  || 'https://supercharge.info/images/avatar-placeholder.png'
        })
      })
      .catch(error => {
        alert(error.message)
      })
  }

  return (
    <KeyboardAvoidingView behavior='padding' style={styles.container}>
      <StatusBar style="light" />
      <Text h3 styele={{ marginTop: 50 }}>
        Create Signal Account
      </Text>
      <View style={styles.inputContainer}>
        <Input placeholder="Full Name" autoFocus type='text' value={name} onChangeText={(text) => setName(text)} inputStyle={{ outline: 'none' }} />
        <Input placeholder="Email"  type='email' value={email} onChangeText={(text) => setEmail(text)} inputStyle={{ outline: 'none' }} />
        <Input placeholder="Password" secureTextEntry type='password' value={password} onChangeText={(text) => setPassword(text)} inputStyle={{ outline: 'none' }} />
        <Input placeholder="Image Url" type='text' value={imageUrl} onChangeText={(text) => setImageUrl(text)} onSubmitEditing={register} inputStyle={{ outline: 'none' }} />
        <Button raised onPress={register} title='Register' />
      </View>
      <View style={{ height: 100 }} />
    </KeyboardAvoidingView>
  )
}

export default RegisterScreen

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
    padding: 10,
    backgroundColor: 'white'
  },
  inputContainer: {
    width: 300
  },
  button: {
    width: 200,
    marginTop: 10
  }
})
