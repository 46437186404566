import React, {useEffect, useState} from 'react'
import { StyleSheet, View, KeyboardAvoidingView } from 'react-native'
import { Button, Input, Image } from 'react-native-elements'
import { StatusBar } from 'expo-status-bar'
import { auth } from '../firebase'
import signalImage from '../assets/signal-logo.png'

const LoginScreen = ({ navigation }) => {
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const signIn = () => {
    auth.signInWithEmailAndPassword(email, password)
      .catch(error => alert(error))
  }

  useEffect(() => {
    const unsuscribe = auth.onAuthStateChanged(authUser => {
      console.log(authUser)
      if(authUser) {
        navigation.replace('Home')
      }
    })

    return unsuscribe
  }, [])

  return (
      <KeyboardAvoidingView behavior='padding'  style={styles.container}>
        <StatusBar style="light" />
        <Image
        source={signalImage}
        style={{ width: 200, height: 200 }} />
        <View style={styles.inputContainer}>
          <Input placeholder="Email" autoFocus type="email" value={email} onChangeText={(text) => setEmail(text)}  inputStyle={{ outline: 'none' }} />
          <Input onSubmitEditing={signIn} placeholder="Password" secureTextEntry type="password" value={password} onChangeText={(text) => setPassword(text)} inputStyle={{ outline: 'none' }} />
          <Button style={styles.button} title="Login" onPress={signIn} />
          <Button onPress={() => navigation.navigate('Register')} style={styles.button} title="Register" type="outline" />
          <View style={{ height: 100 }} />
        </View>
    </KeyboardAvoidingView>
  )
}

export default LoginScreen

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
    padding: 10,
    backgroundColor: 'white'
  },
  inputContainer: {
    width: 300,
  },
  button: {
    width: 300,
    marginTop: 10
  },
  // textarea:focus, input:focus{
  inputBox: {
    outline: 'none',
    // border: '2 solid indigo'
  }
})
